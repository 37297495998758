html {
    --section-background-color: linear-gradient(to bottom left,
            rgba(17, 16, 16, 0.582),
            rgba(12, 8, 24, 0.904));

    --image-gradient: linear-gradient(to bottom left,
            rgba(17, 16, 16, 0.678),
            rgba(12, 10, 22, 0.863));
    --main-color: #209CEE;
    --imp-text-color: #c770f0;
    --color: rgb(32, 156, 238);
}

.purple {
    color: var(--main-color) !important;
}

/* --------- */
/* Preloader */
/* --------- */

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #0c0513;
    background-image: url(./Assets/pre.svg);
    background-repeat: no-repeat;
    background-position: center;
}

#preloader-none {
    opacity: 0;
}

#no-scroll {
    overflow: hidden;
    height: 100vh;
}

/* --------- */
/* Scrollbar */
/* --------- */

::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #2d1950;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(32, 156, 238, 0.959);
    border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(32, 156, 238, 0.911);
    border-radius: 12px;
}

/* -------------- */
/* Navbar Section */
/* -------------- */
.sticky {
    background-color: #212038d8 !important;
    transition: all 0.3s ease-out 0s !important;
    box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
}

.navbar {
    position: fixed;
    transition: all 0.3s ease-out 0s !important;
    padding: 0.3rem 2rem !important;
    font-size: 1.2rem !important;
}

.navbar-brand {
    color: rgb(250, 250, 250) !important;
}

.logo {
    width: 3rem !important;
}

.navbar-toggler {
    position: relative !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.navbar-toggler span {
    display: block;
    background-color: #209CEE !important;
    height: 4px !important;
    width: 27px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    transform: rotate(0deg) !important;
    left: 0 !important;
    opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
    transition: transform 0.35s ease-in-out !important;
    transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(135deg) !important;
    opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px !important;
    visibility: hidden !important;
    background-color: transparent !important;
}


.navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(-135deg) !important;
    opacity: 0.9 !important;
}


.navbar-nav .nav-link {
    color: white !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.nav-link {
    padding: 0.8rem 1rem !important;
}

.navbar-nav .nav-item {
    position: relative;
    margin-left: 20px;
}

.navbar-nav .nav-item a {
    font-weight: 400;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 1;
}

.navbar-nav .nav-item a::after {
    content: "";
    position: relative;
    display: block;
    height: 5px;
    width: 0;
    border-radius: 16px;
    background: #209CEE;
    bottom: 1px;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
    width: 100%;
}

@media (max-width: 767px) {
    .navbar {
        padding: 1rem 2rem !important;
        font-size: 1.4rem !important;
        background-color: #181a27 !important;
    }

    .navbar-nav .nav-item a::after {
        display: none !important;
    }

    .nav-link {
        padding: 0.7rem 1rem !important;
    }
}

/* --------- */
/* Home section */
/* --------- */
#tsParticles {
    position: fixed !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 100%;
    height: 100%;
}

.home-section {
    position: relative;
    z-index: -1;
    background-image: var(--image-gradient), url(./Assets/home-bg.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    padding-bottom: 30px !important;
    padding-top: 30px !important;
}

.home-content {
    padding: 9rem 0 2rem !important;
    color: white !important;
    text-align: left;
}

.heading {
    font-size: 2.4rem !important;
    padding-left: 50px !important;
}

.heading-name {
    font-size: 2.5rem !important;
    padding-left: 45px !important;
}

.main-name {
    color: #209CEE;
    padding-left: 10px !important;
}

.Typewriter__wrapper {
    font-size: 2.2rem !important;
    color: #209CEE !important;
    font-weight: 600 !important;
}

.Typewriter__cursor {
    font-size: 2.4rem !important;
    color: #209CEE !important;
}

.myAvatar {
    justify-content: center;
    padding-top: 9rem !important;
}

.myAvatar img {
    border: 5px solid #209CEE;
    border-radius: 10px;
}

.home-about-section {
    position: relative;
    padding-bottom: 70px !important;
    padding-top: 70px !important;
}

.home-about-description {
    color: white !important;
    padding-top: 100px !important;
    padding-bottom: 20px !important;
    text-align: center;
}

.home-about-body {
    padding-top: 50px;
    font-size: 1.2rem !important;
    text-align: left;
}

.home-about-social {
    text-align: center !important;
    padding-top: 25px;
    color: white !important;
}

.home-about-social-links {
    justify-content: center !important;
    padding-top: 15px !important;
    display: inline-block !important;
    position: relative;
    padding-inline-start: 0 !important;
}

.home-social-icons {
    position: relative !important;
    display: inline-block !important;
    width: 40px !important;
    height: 40px !important;
    text-align: center !important;
    font-size: 1.2rem !important;
    line-height: 2rem !important;
    background: rgba(255, 255, 255, 0.972) !important;
    border-radius: 50% !important;
    transition: 0.5s !important;
}

.home-social-icons::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #209CEE;
    transition: 0.5s;
    transform: scale(0.9);
    z-index: -1;
}

.home-social-icons:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 15px #209CEE;
}

.home-social-icons:hover {
    color: #209CEE;
    box-shadow: 0 0 5px #209CEE;
    text-shadow: 0 0 2px #209CEE;
}

.social-icons {
    display: inline-block !important;
    padding-right: 15px;
    padding-left: 15px;
}

.icon-color {
    color: #209CEE !important;
}

@media (max-width: 767px) {
    .Typewriter__wrapper {
        font-size: 1.4rem !important;
        font-weight: 500 !important;
        position: absolute !important;
    }

    .Typewriter__cursor {
        display: none !important;
    }

    .myAvatar {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }
}

/* --------- */
/* About */
/* --------- */

.about-section {
    position: relative;
    color: white !important;
}

.quote-card-view {
    border: none !important;
    color: white !important;
    background-color: transparent !important;
    text-align: center;
}


.blockqoute p ol {
    text-align: start;
    margin: 10px 0;
}

.blockqoute p ol li {
    margin: 10px 0;
}

.blockqoute p ol li a {
    text-decoration: none;
    text-transform: capitalize;
}

.blockqoute p ol li img {
    width: 100%;
    margin: 10px;
    border-radius: 10px;
    border: #209cee 5px solid;
}

/* --------- */
/* Resume */
/* --------- */

.resume-section {
    position: relative !important;
    padding-top: 110px !important;
    padding-bottom: 30px !important;
    color: white !important;
}

.resume {
    padding-top: 50px;
    padding-bottom: 50px;
    justify-content: center;
}

.resume-left {
    padding-right: 80px !important;
}

.resume-right {
    padding-left: 80px !important;
}

@media (max-width: 767px) {
    .resume-left {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

    .resume-right {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
}

.resume .resume-title {
    font-size: 2em;
    font-weight: 700;
    padding-top: 30px;
    padding-bottom: 30px;
}

.resume .resume-item {
    padding: 0 0 10px 25px;
    margin-top: -2px;
    border-left: 2px solid #209cee;
    position: relative;
}

.resume .resume-item .resume-title {
    line-height: 18px;
    font-size: 0.9em;
    background: #5234795d;
    padding: 8px 15px;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 10px;
}

.resume .resume-item ul {
    padding-left: 20px;
    text-align: justify;
}

.resume .resume-item ul li {
    padding-bottom: 10px;
    list-style: none;
}

.resume .resume-item:last-child {
    padding-bottom: 0;
}

.resume .resume-item::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    left: -9px;
    top: 0;
    background: #fff;
    border: 2px solid #209cee;
}

.like-item {
    padding-top: 10px !important;
    font-size: 1.1em !important;
    font-family: sans-serif !important;
}

.like-btn {
    background-color: #209cee !important;
    border-color: #209cee !important;
    padding: 0.25rem 0.98rem !important;
    border-radius: 5px !important;
    line-height: 1.4 !important;
    transition: 0.3s ease !important;
}

.like-btn:hover {
    transform: translateY(-2px) !important;
    background-color: #209cee !important;
    border-color: #209cee !important;
}

.animate-like {
    animation-name: likeAnimation;
    animation-fill-mode: forwards;
    animation-duration: 0.85s;
}

@keyframes likeAnimation {
    0% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

.fork-btn {
    font-size: 1.1em !important;
    padding-top: 10px !important;
}

.fork-btn-inner {
    line-height: 1.4em !important;
    background-color: #209cee !important;
    padding: 0.25rem 1.1rem !important;
    vertical-align: middle !important;
    text-align: center !important;
}

.fork-btn-inner:hover {
    transform: translateY(-2px) !important;
    background-color: #209cee !important;
    border-color: #209cee !important;
}

.fork-btn-inner::after {
    display: none !important;
}

/* -----------------------*/
/*  Skills */
/* ---------------------- */
.tech-icons {
    font-size: 4.5em !important;
    margin: 15px !important;
    padding: 10px !important;
    opacity: 0.93 !important;
    border: 1.7px solid #209cee !important;
    vertical-align: middle !important;
    text-align: center !important;
    border-radius: 5px !important;
    display: table !important;
    box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
    overflow: hidden !important;
    transition: all 0.4s ease 0s !important;
    color: #209CEE;
}

@media (max-width: 767px) {
    .tech-icons {
        margin: 10px !important;
    }
}

.tech-icons:hover {
    transform: scale(1.05) !important;
    overflow: hidden !important;
    border: 2.2px solid #209cee !important;
}

.tech-icon-images {
    padding: 20px !important;
    line-height: 1.6 !important;
}